"use client";
// @ts-ignore
import { jarallax, jarallaxVideo } from "jarallax";
import { useEffect } from "react";

const jarallaxComponent = () => {

    useEffect(() => {
        jarallaxVideo();
        jarallax(document.querySelectorAll(".jarallax"), {
            speed: 0.2,
            videoSrc: "https://www.youtube.com/shorts/64J0yrtN4y4",
        });
    });

    return <div
        className="my_video absolute inset-0 jarallax"
        data-speed={0}
        data-jarallax-video="https://www.youtube.com/shorts/64J0yrtN4y4"
    />


}
export default jarallaxComponent;