import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ContactFormHandler.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/JarallaxComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/layout/MobileMenu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-image-export-optimizer/dist/ExportedImage.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/img/hero/MelkonHovhannisyanSmiling.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/img/svg/social/crunchbase.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/img/svg/social/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/img/svg/social/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/img/svg/social/twitter.svg");
